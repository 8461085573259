export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCzsPa4VZoF1EQ6ViBq3cyFwFnODGLgWys",
  authDomain: "bookcreator-dev.firebaseapp.com",
  databaseURL: "htts://bookcreator-dev.firebaseio.com",
  storageBucket: "bookcreator-dev.appspot.com",
  messagingSenderId: "409233586464",
};

export const PAYMENTS_DOMAIN = "https://payments.dev.bookcreator.com";
export const LIBRARIES_DOMAIN = "https://libraries.api.dev.bookcreator.com";
export const ANALYTICS_DOMAIN = "https://analytics.api.dev.bookcreator.com";
export const BUTTON_DOMAIN = "https://button.api.dev.bookcreator.com";
export const BUTTON_SDK_DOMAIN = "https://sdk.dev.bookcreator.com";
export const BUTTON_SDK_VERSION = 1;
export const BOOKCREATOR_ORGS_API_URL = "https://organisations.api.dev.bookcreator.com";
export const BOOKCREATOR_API_URL = "https://api.dev.bookcreator.com";
export const BOOKCREATOR_ASSETS_URL = "https://assets.api.dev.bookcreator.com";
export const BOOKCREATOR_APP_URL = "https://app.dev.bookcreator.com";
export const FULLSTORY_ORG_ID = "o-1H302S-na1";

export const GA_ID = "";

export const SENTRY_URL = "";

export const AUTH_COOKIE_NAME = "__admin_session-dev";
export const APP_AUTH_COOKIE_NAME = "__session-dev";
export const AUTH_COOKIE_DOMAIN = "dev.bookcreator.com";
export const LTI_DOMAIN = "lti.dev.bookcreator.com";

export const RECURLY_URL = "https://bookcreator-dev.recurly.com/subscribe/";
export const OFFICE_365_CLIENT_ID = "823d24b1-c34e-4bf0-a00d-38a1e5827557";

export const INTERCOM_APP_ID = "evmyg9ng";
