import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import Form from "../../forms/form";
import FormButton from "../../forms/form-button";
import {
  getOrganisationList,
  organisationActions,
} from "../../../../core/organisation";
import { getAuthToken, isAuthenticated } from "../../../../core/auth";
import SearchableDropdown from "../../forms/searchable-dropdown";
import Loader from "../../ui/loader";
import "./index.scss";

class OrganisationPicker extends Component {
  state = {
    selectedOrg: null,
  };

  componentWillMount = () => {
    const { getOrganisations, loadOrgsOnMount, authToken } = this.props;
    if (loadOrgsOnMount && authToken) {
      getOrganisations();
    }
  };

  componentWillReceiveProps = nextProps => {
    const { authToken, loadOrgsOnMount, getOrganisations } = this.props;
    if (!authToken && nextProps.authToken && loadOrgsOnMount) {
      // get the org id from the url. This is checked and used to set the default org in the action
      const orgId = nextProps.location.state.from.pathname?.split("/")[1];
      getOrganisations(orgId);
    }
  };

  handleSubmit = e => {
    const { setOrganisation, onSubmit } = this.props;
    const { selectedOrg } = this.state;
    setOrganisation(selectedOrg.value);
    if (onSubmit) {
      onSubmit();
    }
    e.preventDefault();
  };

  handleOrgChange = selectedOrg => {
    this.setState({ selectedOrg });
  };

  render() {
    const { organisations, authenticated } = this.props;
    const { selectedOrg } = this.state;

    if (!authenticated) {
      return <Redirect to="/sign-in" />;
    }

    const options = organisations.map(org => {
      return {
        label: org.name,
        value: org,
      };
    });

    const isLoading = !organisations.length;

    return (
      <Form
        header="Select Organization"
        onSubmit={this.handleSubmit}
        className="organisation-picker"
        autocomplete={false}>
        {isLoading ? (
          <div style={{ height: 100 }}>
            <Loader />
          </div>
        ) : (
          <Fragment>
            <Helmet title="Select Organization" />
            <div className="form__content">
              <SearchableDropdown
                value={selectedOrg}
                onChange={this.handleOrgChange}
                placeholder="Select Organization"
                options={options}
              />
            </div>
            <div className="form__actions">
              <FormButton type="submit" text="Submit" disabled={!selectedOrg} />
            </div>
          </Fragment>
        )}
      </Form>
    );
  }
}

const mapStateToProps = createSelector(
  getOrganisationList,
  getAuthToken,
  isAuthenticated,
  (organisations, authToken, authenticated) => ({
    organisations,
    authToken,
    authenticated,
  })
);

const mapDispatchToProps = {
  ...organisationActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationPicker);
